import React from 'react';

import ManageResourcesLayout from '../../layouts/ManageResourcesLayout';
import SaveButton            from '../../buttons/SaveButton';
import UploadButton          from '../../buttons/UploadButton';
import ImageSectionList      from '../../ImageSectionList';

import Util from '../../../util';
import ImagesRepository from '../../../repositories/Images';

class ManageImages extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props.match;
        this.appCode = params.appCode;
        this.imagesRepository = new ImagesRepository(this.appCode);
        this.state = {
            schema : {
                values : {},
                version : null
            },
            updatedSchema : {
                values : {},
                version : null
            },
            previousSchemaVersion : 0,
            uploadedImages : {},
            selectedImages : {},
            baseImages : {},
            hasCreatedNewVersion : false,
            loading : true,
            uploading : false
        }
    }

    componentDidMount = async () => {
        await this.fetchSchema();
        await this.fetchImages();
    }
    
    isBase = () => Util.isBase(this.appCode);

    showMessage = message => {
        alert(message);
    }

    fetchSchema = async () => {
        if (this.isBase()) {
            await this.fetchBaseSchema();
        } else {
            await this.fetchClientSchema();
        }
    }

    fetchBaseSchema = async () => {
        try {
            const schema = await this.imagesRepository.getLatestBaseSchema();
        
            if (schema) this.setState({ schema });
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    fetchClientSchema = async () => {
        try {
            const requiredSchema = await this.imagesRepository.getRequiredAppSchema();
            const existingRequiredAppSchemaVersion = await this.imagesRepository.getRequiredAppSchemaVersionIfExists();
            const latestAppSchema = await this.imagesRepository.getLatestAppSchema();

            if (requiredSchema) {
                if (existingRequiredAppSchemaVersion === requiredSchema.version) {
                    this.setState({ updatedSchema: requiredSchema });
                    this.handleSchemaUpgrade();
                } else {
                    this.setState({ schema: latestAppSchema, updatedSchema: requiredSchema, previousSchemaVersion: latestAppSchema.version });
                }
            } else {
                this.showMessage("No required images version");
            }
        } catch (error) {
            this.showMessage(error.message);
        }
    }

    fetchImages = async () => {
        const { schema, uploadedImages } = this.state;
        const newUploadedImages = { ...uploadedImages };
        const version = schema.version;
        const isBase = this.isBase();

        let operations = [];

        Object.keys(schema.values).map(section => {
            return operations = [...operations, ...Object.keys(schema.values[section].values).map(async image => {
                const imageName = Util.createImageName(section, image);

                try {
                    const imageUrl = await this.imagesRepository.getImage(imageName, version, isBase);

                    return newUploadedImages[section] = {
                        ...newUploadedImages[section],
                        [image] : imageUrl
                    };
                }
                catch (error) {
                    return;
                }
            })];
        });

        await Promise.all(operations);

        this.setState({ uploadedImages : newUploadedImages, loading: false });
    }

    saveSelectedImages = async () => {
        const { selectedImages, schema } = this.state;
        const version = schema.version;

        let operations = [];

        Object.keys(selectedImages).map(section => {
            return operations = [...operations, ...Object.keys(selectedImages[section]).map(async image => {
                try {
                    const imageFile = selectedImages[section][image].file;
                    const imageName = Util.createImageName(section, image);

                    if (schema.values[section]?.values[image]) {
                        await this.imagesRepository.saveImage(imageFile, imageName, version, this.isBase());
                    }
                }
                catch (error) {
                    return this.showMessage(error.message);
                }
            })]
        });

        try {
            await Promise.all(operations);

            await this.imagesRepository.compressImagesToZip(version);
        }
        catch (error) {
            throw new Error('Save images failed');
        }
    }

    incrementVersionIfNecessary = () => {
        const { schema, hasCreatedNewVersion } = this.state;

        if (hasCreatedNewVersion) return;

        this.showMessage('This will create a new version 🍄');

        this.setState({
            schema: {
                ...schema,
                version : schema.version + 1
            },
            hasCreatedNewVersion: true
        });
    }

    handleUpload = async () => {
        const { schema, previousSchemaVersion, selectedImages, uploadedImages, baseImages } = this.state;

        this.setState({ uploading: true });

        if (previousSchemaVersion !== schema.version) {
            try {
                const imagesFromBase = [];
                const imagesFromPrevAppVersion = [];

                Object.keys(schema.values).forEach(section => {
                    Object.keys(schema.values[section].values).forEach(image => {
                        if (!selectedImages[section]?.[image] && uploadedImages[section]?.[image]) {
                            if (baseImages[section]?.[image]) {
                                imagesFromBase.push(Util.createImageName(section, image));
                            } else {
                                imagesFromPrevAppVersion.push(Util.createImageName(section, image));
                            }
                        }
                    })
                });

                if (imagesFromBase) {
                    await this.imagesRepository.copyImages({
                        srcApp      : Util.basePath,
                        destApp     : this.appCode,
                        srcVersion  : schema.version,
                        destVersion : schema.version,
                        images      : imagesFromBase
                    });
                }

                if (schema.version > 1 && imagesFromPrevAppVersion) {
                    await this.imagesRepository.copyImages({
                        srcApp      : this.appCode,
                        destApp     : this.appCode,
                        srcVersion  : previousSchemaVersion,
                        destVersion : schema.version,
                        images      : imagesFromPrevAppVersion
                    });
                }
            }
            catch (error) {
                this.setState({ uploading: false });
                return this.showMessage(error.message);
            }
        }

        try {
            await this.saveSelectedImages();
            this.setState({ previousSchemaVersion: schema.version });
            this.showMessage('Uploaded');
        }
        catch (error) {
            this.showMessage('Upload failed');
        }

        this.setState({ uploading: false });
    }

    handleSave = async () => {
        const { schema, hasCreatedNewVersion, selectedImages, uploadedImages } = this.state;

        const emptyImage = Object.values(schema.values).find(sectionObj => {
            return Object.values(sectionObj.values).find(imageObj => !imageObj.width || !imageObj.height );
        });

        if (emptyImage) return this.showMessage('Empty images are not allowed');

        this.setState({ uploading: true });

        try {
            await this.imagesRepository.saveSchema(schema);
        }
        catch (error) {
            this.setState({ uploading: false });
            return this.showMessage(error.message);
        }

        if (hasCreatedNewVersion) {
            try {
                if (schema.version > 1) {
                    const images = [];

                    Object.keys(schema.values).forEach(section => {
                        Object.keys(schema.values[section].values).forEach(image => {
                            if (!selectedImages[section]?.[image] && uploadedImages[section]?.[image]) {
                                images.push(Util.createImageName(section, image));
                            }
                        })
                    });

                    await this.imagesRepository.copyImages({
                        srcApp      : Util.basePath,
                        destApp     : Util.basePath,
                        srcVersion  : schema.version - 1,
                        destVersion : schema.version,
                        images
                    });
                }
            }
            catch (error) {
                this.setState({ uploading: false });
                return this.showMessage(error.message);
            }
        }

        try {
            await this.saveSelectedImages();
            this.setState({ hasCreatedNewVersion: false });
            this.showMessage('Saved');
        }
        catch (error) {
            this.showMessage('Save failed');
        }

        this.setState({ uploading: false });
    }

    handleSchemaUpgrade = async () => {
        const newBaseImages = { ...this.state.baseImages };
        const newSchema = { ...this.state.updatedSchema };
        const newUploadedImages = { ...this.state.uploadedImages };

        let operations = [];

        Object.keys(newSchema.values).map(section => {
            return operations = [
                ...operations,
                ...Object.keys(newSchema.values[section].values).map(async image => {
                    if (newUploadedImages[section]?.[image]) return;

                    const imageName = Util.createImageName(section, image);

                    try {
                        const imageUrl = await this.imagesRepository.getImage(imageName, newSchema.version, true);

                        newBaseImages[section] = {
                            ...newBaseImages[section],
                            [image] : imageUrl
                        };

                        return newUploadedImages[section] = {
                            ...newUploadedImages[section],
                            [image] : imageUrl
                        };
                    }
                    catch (error) {
                        return;
                    }
                })
            ];
        });

        await Promise.all(operations);

        this.setState({ schema: newSchema, uploadedImages: newUploadedImages, baseImages: newBaseImages });
    }

    handleImageReplaced = (section, imageName, newImageFile) => {
        const { schema } = this.state;
        const resizable = schema.values[section].values[imageName].resizable;
        const requiredWidth = schema.values[section].values[imageName].width;
        const requiredHeight = schema.values[section].values[imageName].height;
        const reader = new FileReader();

        reader.onloadend = () => {
            const url = reader.result;
            const image = new Image();

            image.onload = () => {
                if (!resizable && requiredWidth && requiredHeight &&
                    (image.width !== requiredWidth || image.height !== requiredHeight)) {
                    return this.showMessage(`Image size should be: ${requiredWidth}x${requiredHeight}`);
                }

                this.setState(state => {
                    const newSelectedImages = { ...state.selectedImages };
                    const newSchema = { ...state.schema };

                    if (this.isBase()) {
                        newSchema.values[section].values[imageName].width = image.width;
                        newSchema.values[section].values[imageName].height = image.height;
                    }

                    newSelectedImages[section] = {
                        ...newSelectedImages[section],
                        [imageName] : { file: newImageFile, url }
                    };
            
                    return { selectedImages: newSelectedImages, schema: newSchema };
                })
            }

            image.src = url;
        }

        reader.readAsDataURL(newImageFile);
    }

    handleImageFilesToReplace = files => {
        const { schema } = this.state;

        for (const file of files) {
            const parsedImageName = Util.parseImageName(file.name);

            if (parsedImageName && schema.values[parsedImageName.section]?.values[parsedImageName.image]) {
                this.handleImageReplaced(parsedImageName.section, parsedImageName.image, file);
            }
        }
    }

    handleResizableChange = (section, image, resizable) => {
        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newSchema = { ...state.schema };

            newSchema.values[section].values[image].resizable = resizable;

            return { schema: newSchema };
        });
    }

    handleTooltipChange = (section, image, tooltip) => {
        this.setState(state => {
            const newSchema = { ...state.schema };

            newSchema.values[section].values[image].tooltip = tooltip;

            return { schema: newSchema };
        });
    }

    handleDeleteSection = section => {
        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newSchema = { ...state.schema };

            delete newSchema.values[section];

            return { schema: newSchema };
        });        
    }

    handleDeleteImage = (section, image) => {
        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newSchema = { ...state.schema };

            delete newSchema.values[section].values[image];

            return { schema: newSchema };
        });
    }

    handleAddSection = (name, displayName) => {
        const { schema } = this.state;

        if (!name) return this.showMessage('Type section name');

        if (!displayName) return this.showMessage('Type section display name');

        const formattedName = Util.formatImageName(name);

        if (formattedName in schema.values) return this.showMessage(`Section ${formattedName} already exists`);

        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newSchema = { ...state.schema };

            newSchema.values[formattedName] = { displayName, values: {} };

            return { schema : newSchema };
        });
    }

    handleAddImage = (section, name, displayName) => {
        const { schema } = this.state;

        if (!name) return this.showMessage('Type image name');

        if (!displayName) return this.showMessage('Type image display name');

        const formattedName = Util.formatImageName(name);

        if (formattedName in schema.values[section].values) return this.showMessage(`Image ${formattedName} already exists`);

        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newSchema = { ...state.schema };

            newSchema.values[section].values[formattedName] = { displayName };

            return { schema : newSchema };
        });
    }

    render = () => {
        const { schema, updatedSchema, uploadedImages, selectedImages, uploading, loading } = this.state;
        const title = `${this.isBase() ? '🏡 Base' : this.appCode} Images (Version ${schema.version || 0})`;
        const isModificationAllowed = this.isBase();
        const isUpgradeAvailable = !this.isBase() && schema.version !== updatedSchema.version;
        const Button = this.isBase() ? SaveButton : UploadButton;

        return (
            <ManageResourcesLayout
                title={title}
                button={<Button isUploading={uploading} onClick={this.isBase() ? this.handleSave : this.handleUpload} />}
                isUpgrade={isUpgradeAvailable}
                isLoading={loading}
                onUpgrade={this.handleSchemaUpgrade}
            >
                <ImageSectionList
                    schema={schema}
                    uploadedImages={uploadedImages}
                    selectedImages={selectedImages}
                    isModificationAllowed={isModificationAllowed}
                    onImageChange={this.handleImageReplaced}
                    onImageFilesChange={this.handleImageFilesToReplace}
                    onDeleteSection={this.handleDeleteSection}
                    onDeleteImage={this.handleDeleteImage}
                    onAddSection={this.handleAddSection}
                    onAddItem={this.handleAddImage}
                    onResizableChange={this.handleResizableChange}
                    onTooltipChange={this.handleTooltipChange}
                />
            </ManageResourcesLayout>
        );
    }
}

export default ManageImages;